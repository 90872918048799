










import { Component, Vue } from "vue-property-decorator";
import Header from "./components/layouts/Header.vue";
import Footer from "./components/layouts/Footer.vue";
import Content from "./components/layouts/Content.vue";

@Component({
  components: {
    Header,
    Content,
    Footer,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shipping & Freight Forwarding Company",
    // all titles will be injected into this template
    titleTemplate: "%s | RespectMart",
    meta: [
      {
        name: "keywords",
        content:
          "Import gadgets, Buy in USA from, fastest shipping from USA to Nigeria, Shop in USA from Nigeria, respectmart, RespectMart",
      },
      {
        name: "description",
        content: 'Respectmart is the best Shipping & Freight Forwarding Company that helps businesses & Individuals ship cargo globally using our secure and efficient shipping software solutions.',
          // "RespectMart is a state of the art technology that connects buyers to major e-commerce platforms. We completely eliminate the hassle that comes with Forex and slow shipping. We are at the middle to give you a great shopping experience!",
      },
    ],
  },
})
export default class App extends Vue {}
