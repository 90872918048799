import Vue from "vue";
import VueMeta from "vue-meta";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Loading from "./components/Loading.vue";
import Alert from "./components/Alert.vue";
import "nprogress/nprogress.js";
import "nprogress/nprogress.css";
import VueModal from 'vue-js-modal';

declare global {
  export const NProgress: any;
}

declare module "vue/types/vue" {
  interface Vue {
    $isInternalUrl: (url: string) => boolean;
    $redirect: (url: string, useRouter?: boolean) => void;
    $url: (base: string, query?: Record<string, any>) => string;
  }
}

Vue.component("Alert", Alert);

Vue.mixin({
  methods: {
    $isInternalUrl: function (url: string) {
      const route = this.$router.resolve(url);
      console.log("Resolved route", route);
      return route.resolved.matched.length && route.resolved.name !== "404";
    },
    $redirect: function (location: string, useRouter) {
      const url = new URL(location, window.location.origin).href;
      console.log("Redirecting to ...", url, useRouter);
      if (useRouter && this.$isInternalUrl(url)) {
        return this.$router.replace(url);
      }
      return window.location.replace(url);
    },
    $url: function (base: string, query: Record<string, any> = {}) {
      const [path, search = ""] = base.split("?");
      const params = new URLSearchParams(search);
      for (const [key, value] of Object.entries(query)) {
        params.append(key, value);
      }
      return `${path}?${params.toString()}`;
    },
  },
});

Vue.config.productionTip = false;

Vue.component("Loading", Loading);
Vue.use(VueMeta);
Vue.use(VueModal);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
