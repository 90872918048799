



















































































































































import { Component, Vue } from "vue-property-decorator";
import "flag-icons/css/flag-icons.min.css";

@Component
export default class Footer extends Vue {
  public date = new Date().getFullYear();
	public footerJson = null;
	
	mounted() {
    this.fetchFooterData();
  }

  async fetchFooterData() {
    try {
      const response = await fetch(
        "https://respectmart.com/api/respectmart-footer.json",
        {
          cache: "no-cache",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      this.footerJson = await response.json();

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

}
