import { LazyLoad } from "@/components/LazyLoad";
import Home from "@/views/Home.vue";
import HomeBusiness from "@/views/HomeBusiness.vue";
import Faq from "@/views/Faq.vue";
// import ProhibitedItem from "@/views/ProhibitedItem.vue";
import store from "@/store";
import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";

Vue.use(VueRouter);

enum RouteAccess {
  AUTH = "auth",
  NOAUTH = "no-auth",
  ANY = "any",
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeBusiness,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      LazyLoad(import(/* webpackChunkName: "about" */ "../views/About.vue")),
  },
  {
    path: "/blogs",
    name: "blog",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      LazyLoad(import(/* webpackChunkName: "blog" */ "../views/Blog.vue")),
  },
  {
    path: "/blog/:slug",
    name: "blogDetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      LazyLoad(import(/* webpackChunkName: "blog" */ "../views/BlogDetail.vue")),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      LazyLoad(
        import(/* webpackChunkName: "contact" */ "../views/Contact.vue")
      ),
    meta: {
      access: RouteAccess.ANY,
    },
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      LazyLoad(
        import(/* webpackChunkName: "signup" */ "../views/Register.vue")
      ),
    meta: {
      access: RouteAccess.NOAUTH,
      async beforeResolve(to: Route, from: Route, next: any) {
        next();
      },
    },
    props: (route) => ({
      query: route.query,
    }),
  },
  {
    path: "/password/forgot",
    name: "forgot",
    component: () =>
      LazyLoad(import(/* webpackChunkName: "signup" */ "../views/Forgot.vue")),
    meta: {
      access: RouteAccess.NOAUTH,
      async beforeResolve(to: Route, from: Route, next: any) {
        next();
      },
    },
    props: (route) => ({
      query: route.query,
    }),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      LazyLoad(import(/* webpackChunkName: "signin" */ "../views/Login.vue")),
    meta: {
      access: RouteAccess.NOAUTH,
      async beforeResolve(to: Route, from: Route, next: any) {
        next();
      },
    },
    props: (route) => ({
      query: route.query,
    }),
  },
  {
    path: "/account/profile",
    alias: "/account",
    name: "profile",
    component: () =>
      LazyLoad(
        import(/* webpackChunkName: "profile" */ "../views/Profile.vue")
      ),
    meta: {
      access: RouteAccess.AUTH,
    },
  },
  {
    path: "/account/track-shipping",
    name: "track-shipping",
    component: () =>
      LazyLoad(
        import(
          /* webpackChunkName: "track-shipping" */ "../views/TrackShipping.vue"
        )
      ),
    meta: {
      access: RouteAccess.ANY,
    },
  },
  {
    path: "/dhl/get-quote",
    name: "get-quote",
    component: () =>
      LazyLoad(
        import(
          /* webpackChunkName: "get-Quote" */ "../views/GetQuote.vue"
        )
      ),
    meta: {
      access: RouteAccess.ANY,
    },
  },
  {
    path: "/dhl/ship",
    name: "get-quote",
    component: () =>
      LazyLoad(
        import(
          /* webpackChunkName: "track-shipping" */ "../views/GotoShip.vue"
        )
      ),
    meta: {
      access: RouteAccess.AUTH,
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      LazyLoad(
        import(
          "../views/PrivacyPolicy.vue"
        )
      ),
  },
  {
    path: "/prohibited-items",
    name: "prohibited-items",
    component: () =>
      LazyLoad(
        import(
          "../views/ProhibitedList.vue"
        )
      ),
  },
  {
    path: "/terms",
    name: "terms",
    component: () =>
      LazyLoad(
        import(
          "../views/Terms.vue"
        )
      ),
  },
  {
    path: "/aml",
    name: "aml",
    component: () =>
      LazyLoad(
        import(
          "../views/Aml.vue"
        )
      ),
  },
  {
    path: "*",
    name: "404",
    component: () =>
      LazyLoad(
        import(
          /* webpackChunkName: "generic-error" */ "../views/errors/GenericError.vue"
        )
      ),
    meta: {
      access: RouteAccess.ANY,
    },
    props: {
      title: "404 Page Not Found!",
      description:
        "Oops. The page you were looking for does not exist or may have been moved.",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, _from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  // Check if auth is required on this route
  // (including nested routes).
  const access = to.meta?.access;
  return store
    .dispatch("CHECK_SESSION")
    .then((signedIn) => {
      // If auth isn't required for the route, just continue.
      if (!access || access === RouteAccess.ANY) {
        return next();
      }
      // Then continue if the token still represents a valid user,
      if (access === RouteAccess.AUTH) {
        // otherwise redirect to login.
        signedIn
          ? next()
          : next({ name: "login", query: { continue: to.fullPath } });
        return;
      }
      !signedIn ? next() : next({ name: "profile" });
    })
    .catch((error) => {
      next(error);
    });
});

router.beforeResolve(async (to, from, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of to.matched) {
      await new Promise<void>((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(to, from, (...args: any[]) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (from.name === args[0].name) {
                // Complete the animation of the route progress bar.
                NProgress.done();
              }
              // Complete the redirect.
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    next();
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
