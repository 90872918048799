import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    signedIn: false,
    user: "",
  },
  mutations: {
    SET_CURRENT_USER(state, user) {
      state.user = user;
    },
    SET_SIGNEDIN_STATUS(state, signedIn) {
      state.signedIn = signedIn;
    },
  },
  actions: {
    async CHECK_SESSION({ state, commit }) {
      //   const request = new Request(process.env.VUE_APP_CHECK_SESSION_ENDPOINT, {
      //     method: "head",
      //     mode: "no-cors",
      //     credentials: "include",
      //     redirect: "manual",
      //   });

      //   const response = await fetch(request);
      //   const response = await axios.head(
      //     process.env.VUE_APP_CHECK_SESSION_ENDPOINT,
      //     {
      //       withCredentials: true,
      //       maxRedirects: 0,
      //       validateStatus: () => {
      //         return true;
      //       },
      //     }
      //   );
      //   if (response.status === 200) {
      //     commit("SET_SIGNEDIN_STATUS", true);
      //     // commit("SET_CURRENT_USER", response.data.user);
      //     return true;
      //   }
      //   commit("SET_SIGNEDIN_STATUS", false);
      //       return false;
      return state.signedIn;
    },
  },
  modules: {},
});
