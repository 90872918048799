












import { Component, Vue } from "vue-property-decorator";
import { Alert, AlertComponentInterface } from "./interfaces";

@Component
export default class AlertComponent
  extends Vue
  implements AlertComponentInterface {
  public alerts: Alert[] = [];

  public addAlert(alert: Alert) {
    this.alerts.push(alert);
    return this;
  }

  public clear() {
    this.alerts = [];
    return this;
  }
}
