























































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { MetaInfo } from 'vue-meta';

interface NavItem {
  title: string;
  href: string;
}

const setupMenu = () => {
  const menu = document.querySelector(".menu-block") as Element;
  const menuMain = menu.querySelector(".site-menu-main");
  const submenuAll = menu.querySelectorAll(".sub-menu");
  const goBack = menu.querySelector(".go-back");
  const menuTrigger = document.querySelector(".mobile-menu-trigger");
  const closeMenu = menu.querySelector(".mobile-menu-close");
  let subMenu;
  let subMenuArray: any[] = [];
  let subMenuTextArray: any[] = [];

  function last(array: any[]) {
    return array[array.length - 1];
  }

  function last2(array: any[]) {
    return array[array.length - 2];
  }

  menuMain?.addEventListener("click", (e) => {
    if (!menu.classList.contains("active")) {
      return;
    }
    if ((e.target as any).closest(".nav-item-has-children")) {
      const hasChildren = (e.target as any).closest(".nav-item-has-children");

      showSubMenu(hasChildren);
    }
  });
  goBack?.addEventListener("click", () => {
    const lastItem = last(subMenuArray);
    const lastItemText = last2(subMenuTextArray);
    subMenuArray.pop();
    subMenuTextArray.pop();
    if (subMenuArray.length >= 0) {
      (document.getElementById(lastItem) as HTMLElement).style.animation =
        "slideRight 0.5s ease forwards";
      (menu.querySelector(".current-menu-title") as Element).innerHTML =
        lastItemText;
      setTimeout(() => {
        (document.getElementById(lastItem) as HTMLElement).classList.remove(
          "active"
        );
      }, 300);
    }
    if (subMenuArray.length == 0) {
      menu.querySelector(".mobile-menu-head")?.classList.remove("active");
    }
  });
  menuTrigger?.addEventListener("click", () => {
    toggleMenu();
  });
  closeMenu?.addEventListener("click", () => {
    toggleMenu();
  });
  document.querySelector(".menu-overlay")?.addEventListener("click", () => {
    toggleMenu();
  });

  function toggleMenu() {
    menu.classList.toggle("active");
    document.querySelector(".menu-overlay")?.classList.toggle("active");
  }

  function showSubMenu(hasChildren: Element) {
    for (let i = 0; submenuAll.length < i; i++) {
      submenuAll[i].classList.remove("active");
    }
    subMenu = hasChildren.querySelector(".sub-menu");
    subMenuArray.push(subMenu?.id);
    subMenu?.classList.add("active");
    (subMenu as HTMLElement).style.animation = "slideLeft 0.5s ease forwards";
    const menuTitle = hasChildren.querySelector(".drop-trigger")?.textContent;
    subMenuTextArray.push(menuTitle);

    (menu.querySelector(".current-menu-title") as HTMLElement).innerHTML =
      menuTitle as string;
    menu.querySelector(".mobile-menu-head")?.classList.add("active");
  }
  window.onresize = function () {
    if (this.innerWidth > 991) {
      if (menu.classList.contains("active")) {
        toggleMenu();
      }
    }
  };
};

@Component
export default class Header extends Vue {
  @Prop() private msg!: string;

  public signInUrl = process.env.VUE_APP_SIGNIN_URL;
  public signUpUrl = process.env.VUE_APP_SIGNUP_URL;

  public popover = false;
  public popoverShowClass = "show";

  public navItems: NavItem[] = [
    /*{
      title: "Home",
      href: "/",
    },
    {
      title: "About",
      href: "/about",
    },
    {
      title: "Track Shipping",
      href: "/account/track-shipping",
    },
    {
      title: "Contact Us",
      href: "/contact",
    },*/
  ];

  get signedIn() {
    return this.$store.state.signedIn;
  }

  get username() {
    return this.$store.state.user;
  }

  logout() {
    this.$store.commit("SET_SIGNEDIN_STATUS", false);
    this.$store.commit("SET_CURRENT_USER", "");
    this.$redirect(process.env.VUE_APP_HOME_URL);
  }

  toggleSubmenuNavbar(event: any){
    const target = event.currentTarget as HTMLElement;
    const nextSibling = target.nextElementSibling;

    if (nextSibling) {
      nextSibling.classList.toggle('show');
    }
  }

  metaInfo(): MetaInfo {
    return {
      title: 'Freight forwarding software for Businesses | Respectmart',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Are you a Business? Manage your shipments effortlessly using our all-in-one freight forwarding software that helps you move cargo via Sea or air.'
        },
      ]
    };
  }

  mounted() {
    setupMenu();
  }
}
